import React from 'react'

import SEO from '../components/seo'
import Layout from '../components/layout'

// Sections
import AboutSection from '../components/about-section/about-section'

const Story = () => {
  return (
    <Layout>
      <SEO title="Creatives Near You | Our Story" />
      <AboutSection />
    </Layout>
  )
}

export default Story