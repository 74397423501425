import React, { useEffect, useState } from "react";
import OurStoryWebView from "../../images/our-story-web-view.png";
import OurStoryMobileView from "../../images/our-story-phone-view.png";

import "./about-section.scss";

const AboutSection = () => {
  useEffect(() => {
    setOnTableOrMobileSize(window.innerWidth <= 900);
  });

  const [onTableOrMobileSize, setOnTableOrMobileSize] = useState(false);
  const graphicToUse = onTableOrMobileSize
    ? OurStoryMobileView
    : OurStoryWebView;

  return (
    <div className="about">
      <div className="about__text">
        <img className="about__graphic" src={graphicToUse} />
        <p>
          Creatives Near You (cnu) was created with one mission. To help
          creatives find new potential connections in their area and beyond. The
          creative community invests time, energy, and money into skills that
          too often fail to bloom due to lack of creative resources, even when
          they are all around. CNU helps shine a light on a disconnected
          creative world that is always on a journey for it’s next masterpiece.
        </p>
        <p>
          The obstacles of the creative world were known all too well by creator
          and owner Christopher Sinanan. As a record producer, engineer, and
          songwriter for over 15 years, he noticed a huge change in the creative
          process for not just music, but all creative fields. After concluding
          his time at Bloomfield College where he received his BA in Music
          Technology and Audio Engineering, he sought out opportunities in his
          field. After 2 years of unpaid internships in studios and post
          production houses he became discouraged when he was not given a chance
          to excel beyond his intern status. He began to utilize his income
          towards building up a personal recording space at his house to further
          develop his skills and make side income as an independent audio
          engineer. It was here where the epiphone of what would eventually
          become cnu started.
        </p>
        <p>
          Technology, programs, and available resources have paved the way for
          creators to develop independent work stations that could produce work
          that is on par with professional grade facilities. The only missing
          piece of the puzzle was the ability to spotlight these facilities by
          utilizing the rise of GPS technology which made companies like Uber,
          Yelp, and Tinder become modern day innovative powerhouses in the
          business world. The ability to conduct searches for a creative in a
          specific area is a powerful, and much needed tool for the creative
          world. This consolidated pooling of creatives' entire catalog of
          external online resources paired with their geographical location
          helps create a detailed footprint of every user enrolled in the app.
          From here users are able to establish connections, exchange work,
          schedule meetups, and review each other.
        </p>
        <p>
          CNU connects the experienced to the novice, the hopeful to the hungry,
          and imaginative to the dream makers. It is our hope that this mobile
          application helps bring forth art infused with the culture and
          heritage of the environments where they were formed. We will help
          eliminate roadblocks standing in the way of creative ambitions to
          assure that resources, talent, and possibilities are never in short
          supply.
        </p>
      </div>
    </div>
  );
};

export default AboutSection;
